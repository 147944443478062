/* Default styles for larger screens */
.profile-area {
  background-image: linear-gradient(rgba(4, 9, 30, 0.5), rgba(4, 9, 30, 0.5)), url("/public/images/about-us-bg.jpg");

  padding: 80px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3rem;
}

.card {
  flex: 1;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  border-radius: 15px;
  margin: 10px;
  min-width: 300px;
  max-width: calc(33.33% - 20px);
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out;
  background-color: #fff;
}

.card:hover {
  transform: scale(1.05);
}

.img1 img {
  height: 200px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  width: 100%;
}

.img2 img {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 7px solid #fff;
  margin-top: -80px;
}

.card:hover .img1 img {
  border-color: darkcyan;
  transition: 0.7s;
}

.main-text {
  padding: 30px 0;
  text-align: center;
}

.main-text h2 {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 20px;
  margin: 0 0 20px;
}

.main-text p {
  font-size: 16px;
  padding: 0 35px;
}

.social-icons {
  text-align: center;
  padding-bottom: 20px;
  justify-content: center;
  margin-left: 30px;
}

.social-icons i {
  font-size: 20px;
  color: darkcyan;
  padding: 0 10px;
}

/* Media query for screens smaller than 768px (tablets) */
@media (max-width: 768px) {
  .profile-area {
    padding-top: 45rem;
    overflow: scroll;
  }

  .row {
    flex-direction: column; /* Stack cards vertically on smaller screens */
  }

  .card {
    max-width: 100%; /* Cards take up full width on smaller screens */
  }

  .social-icons {
    margin-left: 0; /* Reset margin for social icons */
  }
}

/* Media query for screens smaller than 576px (mobile) */
@media (max-width: 576px) {
  .profile-area {
    padding-top: 45rem;
    overflow: scroll;
  }

  .card {
    margin: 10px 0; /* Add space between stacked cards */
  }
}
