* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.home {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
}

.contact_us {
  background-image: linear-gradient(rgba(4, 9, 30, 0.5), rgba(4, 9, 30, 0.5)), url("../public/images/contactus.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.events {
  text-align: center;
  padding: 20px;
}

.events_cover {
  background-image: linear-gradient(rgba(4, 9, 30, 0.5), rgba(4, 9, 30, 0.5)), url("/public/images/about-us-bg.jpg");
  background-size: cover;
}
.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel-item {
  width: 100%;
  max-width: 550px; /* Adjust the max-width as needed */
  margin: 20px 0;
  background-color: #fff; /* Adjust background color as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a shadow or other styling */
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.carousel-text {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px;
  width: 100%;
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.carousel-item:hover .carousel-text {
  transform: translateY(0);
  opacity: 1;
}

.carousel-container h1 {
  font-size: 1.5rem; /* Adjust font size as needed */
  margin: 20px 0;
  color: #fff; /* Adjust text color as needed */
}

.carousel-item img {
  max-width: 100%; /* Allow images to resize proportionally */
  max-height: 750px; /* Adjust the max height as needed */
  height: auto; /* Allow images to maintain their aspect ratio */
}

.container_clubs {
  background-image: linear-gradient(rgba(4, 9, 30, 0.5), rgba(4, 9, 30, 0.5)), url("/public/images/about-us-bg.jpg");
  margin: 0;
  padding: 20px 0;
  background-size: cover;
  height: 100%;
}
.clubs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  margin: auto;
  max-width: 1200px;
}

.club {
  text-align: center;
  border-radius: 5%;
  padding: 20px;
  background-color: #f96167;
  max-width: 400px;
}

.club img {
  max-width: 100%;
  height: auto;
}

.club p {
  display: none;
}

.club:hover p {
  display: block;
  opacity: 1;
}
