:root {
  --primary: #f96167;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  color: #f9e795;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #f96167;

  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 20px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  background: #f96167;

  color: #f9e795;
  transition: all 0.3s ease-out;
}
